import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import ErrorModal from '../errorModal';
import { DEACTIVATE_REPLICA_CLIENT } from '../../gql/replicas';

const useStyles = makeStyles(() => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginBottom: '10px',
    backgroundColor: '#FFD2D2 !important',
    width: '120px !important',
  },
}));

const DeactivateReplicaButton = function (props) {
  const classes = useStyles();
  const [deactivate, { data, loading, error }] = useMutation(DEACTIVATE_REPLICA_CLIENT);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    deactivate({ variables: { schema: props.schema } })
    setOpen(false);
  };

  useEffect(() => {
    if (data) {
      data.DeactivateReplicaClient.success ? window.location.reload() : null;
    }
  }, [data]);

  if (error) {
    return <ErrorModal error={error} />;
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

return (
  <div>
      <Button className={classes.button} variant="contained" onClick={handleClickOpen}>
      Desactivar réplica
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          ¿Desactivar la réplica?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desactivar la réplica eliminará la instancia de BBDD, los permisos de IP y las conexiones con Airflow.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleAgree} autoFocus>
            Continuar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeactivateReplicaButton;
