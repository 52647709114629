import { gql } from '@apollo/client';

export const LIST = gql`
  query($stateFilter: [String], $rutFilter: [String], $applicantAreaFilter: [String]) {
      allBasesBackoffice(stateFilter:$stateFilter, rutFilter:$rutFilter, applicantAreaFilter:$applicantAreaFilter) {
          dominio
          esquema
          rutContrato
          email
          createdAt
          updatedAt
          cantUsuarios
          razonSocialContrato
          estado
          contractNumber
          realUsersQuantity
          solicitantUserEmail
          contractState
          applicantArea
          

      }
  }
  `;

export const ADD_DB = gql`
  mutation AddBaseBackoffice($contractRut: String!, $domain: String!, $contractCompanyName: String!, $schema: String!, $userAmount: Int!, $email: String!, $registerNumber: Int!, $applicantArea: String!, $state: String!){
      AddBaseBackoffice(contractRut: $contractRut, domain: $domain, contractCompanyName: $contractCompanyName, schema: $schema, userAmount: $userAmount, email: $email, registerNumber: $registerNumber, applicantArea: $applicantArea, state: $state){
      success
      }
  }`;
