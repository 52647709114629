import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@material-ui/core';
import { DELETE_BACKUP } from '../gql/backups';
import ErrorModal from './errorModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '50px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    marginBottom: '10px',
    backgroundColor: '#FFD2D2 !important',
    width: '120px !important',
  },
}));

const DeleteBackupButton = function (props) {
  const classes = useStyles();
  const [addTodo, { data, loading, error }] = useMutation(DELETE_BACKUP, { errorPolicy: 'all' });
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (props.status === 'B') {
      setDisabled(false);
    }
  }, []);
  useEffect(() => {
    if (data) {
      data.DeleteBackupBackoffice.success ? window.location.reload() : null;
    }
  }, [data]);

  if (error) {
    return <ErrorModal error={error} />;
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Button disabled={disabled} className={classes.button} onClick={() => addTodo({ variables: { baseId: props.domain } })} variant="contained">
      {disabled ? 'Inhabilitado' : 'Eliminar'}
    </Button>
  );
};

export default DeleteBackupButton;
