import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Box, Button, CircularProgress, SwipeableDrawer } from "@material-ui/core";
import AppBarForm from "./AppBarForm";

const useStyles = makeStyles(() => ({
  sidebar: {
    height: "100%",
    maxWidth: 700,
    minWidth: 650,
  },
  appbar: {
    padding: "1rem",
  },
  closeIcon: {
    padding: 0,
    marginRight: "1rem",
  },
  children: {
    padding: "1rem 1.5rem",
    flexGrow: 1,
    overflow: "auto",
    backgroundColor: "#fff",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "sticky",
    bottom: "0px",
    paddingBottom: "10px",
    backgroundColor: "white",
    paddingTop: "10px",
    zIndex: 1,
    borderTop: "1px solid #e5e5e5",
  },
  button: {
    backgroundColor: "#033771",
    color: "#ffffff",
    padding: "0.4rem",
    "&:hover": {
      backgroundColor: "#033771",
    },
    "&:disabled": {
      backgroundColor: "#e5e5e5",
      color: "#cccccc",
    },
  },
  paper: {
    overflowY: "inherit",
  },
}));

const SidebarForm = ({
  state,
  setState,
  header,
  children,
  title,
  titleRight,
  onApplyButton,
  buttonLabel,
  loadingApplyButton,
  disabledApplyButton,
}) => {
  const classes = useStyles();
  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={state}
        onClose={() => setState(false)}
        onOpen={() => setState(false)}
        classes={{
          paperAnchorRight: classes.paper,
        }}
      >
        <div
          role="presentation"
          className={clsx(classes.sidebar)}
          style={{
            display: "contents",
          }}
        >
          <AppBarForm setState={setState} title={title} titleRight={titleRight}>
            {header}
          </AppBarForm>
          <Box className={classes.children}>{children}</Box>
          {(
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                onClick={() => {
                  onApplyButton();
                }}
                disabled={loadingApplyButton || disabledApplyButton}
                endIcon={loadingApplyButton ? <CircularProgress size={20} /> : null}
                className={classes.button}
              >
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </SwipeableDrawer>
    </div>
  );
};

SidebarForm.propTypes = {
  state: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  title: PropTypes.string,
  titleRight: PropTypes.string,
  header: PropTypes.node,
  children: PropTypes.node.isRequired,
  onApplyButton: PropTypes.func,
  buttonLabel: PropTypes.string.isRequired,
  loadingApplyButton: PropTypes.bool,
  disabledApplyButton: PropTypes.bool,
};

SidebarForm.defaultProps = {
  titleRight: "",
  title: "",
  header: null,
  onApplyButton: null,
  loadingApplyButton: false,
  disabledApplyButton: false,
};

export default SidebarForm;