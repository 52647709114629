// @ts-nocheck
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Container, Paper, Typography, Grid,
} from '@material-ui/core';
import DBList from '../../components/dbList';
import MainDrawer from '../../components/mainDrawer';
import { useState } from "react";
import BaseFilters from "./baseFilter";
import { LIST } from '../../gql/home';
import { useQuery } from '@apollo/client';


const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px !important',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '25px',
    marginRight: '25px',
  },
}));


const defaultVariables = {
  selectedRuts: [],
  selectedStates: [],
  selectedAreas: [],
}
  


const Home = function () {
  const classes = useStyles();
  const [filters, setFilters] = useState({
    first: 10,
    offset: 0,
    search: "",
    orderBy: "razonSocial",
  });
  const [ruts, setRuts] = useState([]);
  const [states, setStates] = useState([]);
  const [areas, setAreas] = useState([]);

 
  const [variables, setVariables] = useState(defaultVariables);

  const variableChange = (variableName, newValue) => {
    if (variableName === "rut") {
      setVariables({...variables, selectedRuts: []});
    } else if (variableName === "state") {
      setVariables({...variables, selectedStates: []});
    } else if (variableName === "area") {
      setVariables({...variables, selectedAreas: []});
    } else if (variableName[0]) {
      if (variableName[0].type === "rut") {
        setVariables({...variables, selectedRuts: variableName});
      } else if (variableName[0].type === "state") {
        setVariables({...variables, selectedStates: variableName});
      } else if (variableName[0].type === "area") {
        setVariables({...variables, selectedAreas: variableName});
      }
       } 
    };

  const generateFilter = () => {
    const rutsId = variables.selectedRuts.map(({id})=>id)
    const statesId = variables.selectedStates.map(({id})=>id)
    const areasId = variables.selectedAreas.map(({id})=>id)
    setRuts(rutsId);
    setStates(statesId);
    setAreas(areasId);
    setSideBarOpen(false);
  };

  const { loading, error, data } = useQuery(LIST, {variables: {stateFilter:states, rutFilter:ruts, applicantAreaFilter:areas}});

  const [sideBarOpen, setSideBarOpen] = useState(false);

  return (
    <div>
      <MainDrawer buttons={[{ text: 'Home', link: '/' }]} />
      <Container component={Paper} style={{ padding: '20px', paddingBottom: '50px', maxWidth: '1600px' }}>
        <div className={classes.header}>
          <Typography align="left" variant="h2">
            Lista de clientes
          </Typography>

          <Button variant="contained" color="secondary" href="/addschema">
            Nuevo cliente
          </Button>
          <Button variant="contained" color="secondary" onClick={()=> setSideBarOpen(true)}>
            Filtrar
          </Button>
          
        </div>
        </Container>
      
        <DBList data={data} error={error} loading={loading}/>

        <BaseFilters
        filters={{
          include: true,
          show: true,
          general: true,
          article: true,
          indicators: true,
        }}
        sideBarOpen={sideBarOpen}
        setSideBarOpen={setSideBarOpen}
        variableChange={variableChange}
        variables={variables}
        generateFilter={() => generateFilter({ variables })}
        //setDefaultVariables={setDefaultVariables}
        //selectedCompany={selectedCompanies}
      />
      
    </div>
  );
};

export default Home;
