import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  CircularProgress, makeStyles, Paper, TableContainer,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { BackupsQuery } from '../gql/backups';
import ErrorModal from './errorModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    height: '70vh',
    width: '100%',
  },
}));

const columns = [
  {
    field: 'domain',
    headerName: 'Dominio',
    width: 150,
  },
  {
    field: 'schema',
    headerName: 'Esquema',
    width: 150,
  },
  {
    field: 'celeryProcessId',
    headerName: 'Id Celery',
    width: 300,
  },
  {
    field: 'status',
    headerName: 'Estado',
    width: 170,
  },
];

const BackupList = function () {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const { loading, error, data } = useQuery(
    BackupsQuery, {
    pollInterval: 2000,
  });

  useEffect(() => {
    if (data) {
      const newRows = data.allBackupProcess.map((item) => ({
        id: item.id,
        celeryProcessId: item.celeryProcessId,
        status: item.status,
        domain: item.base.dominio,
        schema: item.base.esquema,
      }));
      setRows(newRows);
    }
  }, [data]);

  if (loading) return <div className={classes.loadingContainer}><CircularProgress /></div>;
  if (error) return <ErrorModal error={error} />;

  return (
    <TableContainer component={Paper}>
      <div className={classes.table}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={15}
          rowsPerPageOptions={[15]}
        />
      </div>
    </TableContainer>

  );
};
export default BackupList;
