import { gql } from '@apollo/client';

export const LAST_LOGIN_SCHEMAS = gql`
mutation LastLoginReport {
  LastLoginReport{
    success
    report
  }
}`;

export const UNUSED_SCHEMAS = gql`
mutation UnusedSchemasReport {
  UnusedSchemasReport{
    success
    report
  }
}`;

export const API_LOGS = gql`
mutation APILogsReport {
  APILogsReport{
    success
    report
  }
}`;

export const USERS_TYPE_I = gql`
mutation UsersTypeIReport {
  UsersTypeIReport{
    success
    report
  }
}`;