import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation } from 'react-router';
import EditUser from './editUser';
import { LIST } from '../gql/users';
import ErrorModal from './errorModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const UserList = function (props) {
  const classes = useStyles();
  const location = useLocation();
  const urlArray = location.pathname.split('/client/');
  const schema = urlArray[urlArray.length - 1].replace('/', '');
  const {
    loading, error, data, refetch,
  } = useQuery(LIST, { variables: { schema } });

  const columns = [
    { field: 'username', headerName: 'Nombre de usuario', width: 230 },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
    },
    {
      field: 'isSuperuser',
      headerName: 'Superuser',
      width: 150,
    },
    {
      field: 'isStaff',
      headerName: 'Staff',
      width: 150,
    },
    {
      field: 'edit',
      renderCell: (cellValues) => (
        <EditUser reloadParent={refetch} data={cellValues.row} />
      ),
      headerName: 'Edit',
      width: 100,
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [refresh, setRefresh] = useState(props.refresh);

  useEffect(() => {
    setRefresh(props.refresh);
    refetch();
  }, [props.refresh]);

  if (loading) return <div className={classes.loadingContainer}><CircularProgress /></div>;
  if (error) return <ErrorModal error={error} />;

  return (
    <div style={{ height: 350, width: '100%' }}>
      <DataGrid
        rows={data.getAllUsersBackoffice}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
};
export default UserList;
