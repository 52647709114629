// @ts-nocheck
/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Container, Paper, Typography,
} from '@material-ui/core';
import MainDrawer from '../../components/mainDrawer';
import ReplicasList from '../../components/replicas/replicasList';

const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px !important',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '25px',
    marginRight: '25px',
  },
}));

const ReplicaClients = function () {
  const classes = useStyles();

  return (
    <div>
      <MainDrawer buttons={[{ text: 'Home', link: '/' }]} />
      <Container component={Paper} style={{ padding: '20px', paddingBottom: '50px', maxWidth: '1600px' }}>
        <div className={classes.header}>
          <Typography align="left" variant="h2">
            Clientes con servicio de réplicas
          </Typography>
          <Button variant="contained" color="secondary" href="/add_replica_client">
            Activar para un nuevo cliente
          </Button>
        </div>
        <ReplicasList />
      </Container>
    </div>
  );
};

export default ReplicaClients;

