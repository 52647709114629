import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, TextField, Container, Typography, CircularProgress,
} from '@material-ui/core';
import { ADD_DB } from '../gql/home';
import ErrorModal from './errorModal';
import MenuItem from '@mui/material/MenuItem';

const DATA_TEMPLATE = {
  contractRut: '',
  domain: '',
  contractCompanyName: '',
  schema: '',
  userAmount: 0,
  email: '',
  registerNumber: 0,
  applicantArea: '',
  state: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    marginBottom: '10px',
  },
  input: {
    width: '100%',
  },
}));


  
const AddDBForm = function () {
  const classes = useStyles();
  const [baseData, setBaseData] = useState(DATA_TEMPLATE);

  

  const [addTodo, { data, loading, error }] = useMutation(ADD_DB, { errorPolicy: 'all' });

  if (loading) return <CircularProgress />;
  if (data && !error) {
    return <h2>Base creada con éxito</h2>;
  }
  if (error) return <ErrorModal error={error} />;

  return (
    
    
    <Container>
      <form>
        <Typography className={classes.header} color="textSecondary" align="center">
          Agrega una nueva
          {' '}
          <b>base</b>
        </Typography>
        <TextField className={classes.input} id="outlined-basic" label="Rut" variant="outlined" onChange={(e) => setBaseData({ ...baseData, contractRut: e.target.value })} />
        <TextField className={classes.input} id="outlined-basic" label="Razón social" variant="outlined" onChange={(e) => setBaseData({ ...baseData, contractCompanyName: e.target.value })} />
        <TextField className={classes.input} id="outlined-basic" label="Dominio" variant="outlined" onChange={(e) => setBaseData({ ...baseData, domain: e.target.value })} />
        <TextField className={classes.input} id="outlined-basic" label="Esquema" variant="outlined" onChange={(e) => setBaseData({ ...baseData, schema: e.target.value })} />
        <TextField className={classes.input} id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setBaseData({ ...baseData, email: e.target.value })} />
        <TextField className={classes.input} id="outlined-basic" label="N° usuarios" variant="outlined" type="number" InputProps={{ inputProps: { min: 0 } }} onChange={(e) => setBaseData({ ...baseData, userAmount: parseInt(e.target.value) })} />

        <TextField
          id="outlined-basic"
          select
          label="Estado"
          className={classes.input}
          variant="outlined"
          onChange={(e) => setBaseData({ ...baseData, state: e.target.value })}
        >
          <MenuItem value={"A"}>Activo</MenuItem>
          <MenuItem value={"D"}>Docencia</MenuItem>
          <MenuItem value={"U"}>Uso interno</MenuItem>
          <MenuItem value={"M"}>Implementación</MenuItem>
        </TextField>


        <TextField
          id="outlined-basic"
          select
          label="Área solicitante"
          className={classes.input}
          variant="outlined"
          onChange={(e) => setBaseData({ ...baseData, applicantArea: e.target.value })}
        >
              <MenuItem value={"consultoria"}>Consultoría</MenuItem>
              <MenuItem value={"comercial"}>Comercial</MenuItem>
              <MenuItem value={"producto"}>Producto</MenuItem>
              <MenuItem value={"desarrollo"}>Desarrollo</MenuItem>
              <MenuItem value={"soporte"}>Soporte</MenuItem>
              <MenuItem value={"centro_de_capacitacion"}>Centro de capacitación</MenuItem>


        </TextField>
        {baseData.state==="A" && <TextField className={classes.input} id="outlined-basic" label="N° Registro" variant="outlined" type="number" onChange={(e) => setBaseData({ ...baseData, registerNumber: parseInt(e.target.value) })} />}
        {baseData.state==="M" && <TextField className={classes.input} id="outlined-basic" label="N° Registro" variant="outlined" type="number" onChange={(e) => setBaseData({ ...baseData, registerNumber: parseInt(e.target.value) })} />}

        <Button className={classes.input} variant="contained" onClick={() => addTodo({ variables: baseData })}> Agregar </Button>
      </form>
    </Container>
  );
};

export default AddDBForm;
