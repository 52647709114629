import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button, TextField, Dialog, DialogContent, DialogTitle, CircularProgress,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import { ADD_COMPANY, GET_COMUNAS } from '../gql/company';
import ErrorModal from './errorModal';

const DATA_TEMPLATE = {
  city: 0,
  comuna: 0,
  country: 0,
  currency: null,
  giro: '',
  name: '',
  razonSocial: '',
  region: 0,
  rut: '',
  schema: '',

};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoManagermas: {
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
    width: 200,
  },
  containerLogo: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
    marginBottom: '2rem',
  },
  loginMessage: {
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  forgotPasswordLink: {
    alignSelf: 'center',
    fontSize: 'small',
    color: theme.palette.link.main,
  },
  tooltip: {
    fontSize: 20,
  },
  header: {
    marginBottom: '10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AddCompanyForm = function (props) {
  const urlArray = location.pathname.split('/client/');
  const schema = urlArray[urlArray.length - 1].replace('/', '');
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [baseData, setBaseData] = useState(DATA_TEMPLATE);
  const [comunas, setComunas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [addTodo, { data, loading, error }] = useMutation(ADD_COMPANY);
  const { loading: loadingComunas, error: errorComunas, data: dataComunas } = useQuery(GET_COMUNAS, {
    variables: {
      schema,
    },
  });

  useEffect(() => {
    setBaseData({ ...baseData, schema });
  }, []);

  useEffect(() => {
    if (dataComunas) {
      setComunas(dataComunas.getTablaGeneralBackoffice);
    }
  }, [dataComunas]);

  const handleSubmit = async () => {
    await addTodo({ variables: baseData });
    props.triggerRefetch();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error || errorComunas) return <ErrorModal error={error ? error : errorComunas}></ErrorModal>;

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={() => handleOpen()}>
        Nueva empresa
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h2>Agregar empresa</h2></DialogTitle>
        </div>
        <DialogContent>
          { !loading && !loadingComunas
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <TextField id="outlined-basic" label="Rut" variant="outlined" onChange={(e) => setBaseData({ ...baseData, rut: e.target.value })} />
                <TextField id="outlined-basic" label="Razón social" variant="outlined" onChange={(e) => setBaseData({ ...baseData, razonSocial: e.target.value })} />
                <TextField id="outlined-basic" label="Giro" variant="outlined" onChange={(e) => setBaseData({ ...baseData, giro: e.target.value })} />
                <TextField id="outlined-basic" label="Nombre de fantasía" variant="outlined" onChange={(e) => setBaseData({ ...baseData, name: e.target.value })} />
                <Autocomplete
                  disablePortal
                  id="comunas-selector"
                  getOptionLabel={(option) => option.descripcion}
                  options={comunas}
                  onChange={(e, value) => (value ? setBaseData({ ...baseData, comuna: value.id }) 
                  : setBaseData({ ...baseData, comuna: null }))}
                  renderInput={(params) => <TextField {...params} label="Comuna" variant="outlined" />}
                />
                <Button variant="contained" onClick={() => handleSubmit()}> Agregar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCompanyForm;
