import { gql } from '@apollo/client';

export const ADD_COMPANY = gql`
mutation AddCompanyBackoffice(
  $comuna: Int
  $currency: Int
  $giro: String
  $name: String
  $razonSocial: String!
  $rut: String!
  $schema: String!
){
  AddCompanyBackoffice(
    comuna: $comuna
    currency: $currency
    giro: $giro
    name: $name
    razonSocial:$razonSocial
    rut: $rut
    schema: $schema
  ){
    success
    
  }
}`;

export const GET_COMUNAS = gql`
query getTablaGeneralBackoffice($schema: String!){
  getTablaGeneralBackoffice(schema: $schema, tableToGet: "comunas"){
    id
    codExt
    codigo
    descripcion
    numAux
  }
}
`;

export const LIST = gql`
query allCompaniesBackoffice($schema: String){
  allCompaniesBackoffice(schema: $schema){
    empresa
    nomFantasia
    rut
    giro
    emailContac
    estado
    updatedAt
  }
}
`;

export const EDIT_COMPANY = gql`
mutation EditCompanyBackoffice(
  $ciudad: Int
  $companyId: Int!
  $comuna: Int
  $direccion: String
  $giro: String
  $nomFantasia: String
  $pais: Int
  $razonSocial: String
  $region: Int
  $rut: String
  $schema: String!
  $emailContac: String
){
  EditCompanyBackoffice(
    ciudad: $ciudad
    companyId: $companyId
    comuna: $comuna
    direccion: $direccion
    giro: $giro
    nomFantasia: $nomFantasia
    pais: $pais
    razonSocial: $razonSocial
    region: $region
    rut: $rut
    schema: $schema
    emailContac: $emailContac
  ){
    success
  }
}`;
