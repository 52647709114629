import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import LoginForm from './LoginForm';
import { token } from '../../apollo/sessionHelper';

const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
}));

const Login = function () {
  const classes = useStyles();

  if (token.call()) {
    alert('Ya estás logueado');
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.backgroundLogin}>
      <LoginForm />
    </div>
  );
};

export default Login;
