import React, { useState } from 'react';
import {
  Drawer, Link, List, ListItem, ListItemIcon, ListItemText, makeStyles, Toolbar,
} from '@material-ui/core';
import LogoutIcon from '@mui/icons-material/Logout';
import { Redirect } from 'react-router';
import logo from '../assets/images/logo-manager.svg';
import { logOut } from '../apollo/sessionFunctions';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  logo: {
    height: '40px',
    marginBottom: '20px',
  },
  listDivider: {
    height: 'clamp(50px, 45vh ,60vh)',
  },
}));

const MainDrawer = function () {
  const classes = useStyles();
  const [logout, setLogout] = useState(false);

  const handleLogout = () => {
    logOut();
    setLogout(true);
  };

  if (logout) { return <Redirect to="/login" />; }

  return (
    <Drawer
      sx={{
        width: '600px',
        '& .MuiPaper-root': {
          width: '600px',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <List>
        <ListItem>
          <img className={classes.logo} src={logo} />
        </ListItem>
        <Link href="/home" color="inherit" underline="none">
          <ListItem button>
            <ListItemText primary="Home" />
          </ListItem>
        </Link>
        <Link href="/email" color="inherit" underline="none">
          <ListItem button>
            <ListItemText primary="Email" />
          </ListItem>
        </Link>
        <Link href="/backups" color="inherit" underline="none">
          <ListItem button>
            <ListItemText primary="Backups" />
          </ListItem>
        </Link>
        <Link href="/reports" color="inherit" underline="none">
          <ListItem button>
            <ListItemText primary="Reports" />
          </ListItem>
        </Link>
        <Link href="/replica_clients" color="inherit" underline="none">
          <ListItem button>
            <ListItemText primary="Replica Clients" />
          </ListItem>
        </Link>
        <div className={classes.listDivider} />
        <ListItem button onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Cerrar sesión" />
        </ListItem>
      </List>
    </Drawer>
  );
};
export default MainDrawer;
