import { gql } from '@apollo/client';

export const LOGIN_1 = gql`
    mutation tokenAuth($username: String!, $password: String!){
      tokenAuth(username: $username, password: $password){
        token
        refreshExpiresIn
        }
      }`;

export const LOGIN_2 = gql`
    mutation{
      LoginBackoffice{
        success
        }
      }`;
