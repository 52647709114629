import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button,
} from '@material-ui/core';
import { API_LOGS, LAST_LOGIN_SCHEMAS, UNUSED_SCHEMAS, USERS_TYPE_I } from '../gql/reports';
import { CircularProgress } from '@mui/material';
import ErrorModal from './errorModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '50px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const reports = {
  'LastLoginReport': LAST_LOGIN_SCHEMAS,
  'UnusedSchemasReport': UNUSED_SCHEMAS,
  'APILogsReport': API_LOGS,
  'UsersTypeIReport': USERS_TYPE_I,
}

const DownloadReportButton = function (props) {
  const classes = useStyles();
  const [addTodo, { data, loading, error }] = useMutation(reports[props.download], { errorPolicy: 'all' });

  useEffect(() => {
    if (data && data[props.download]) {
      const element = document.createElement("a");
      const file = new Blob([data[props.download].report], {type: 'text/csv'});
      element.href = URL.createObjectURL(file);
      element.download = `${props.download}.csv`;
      document.body.appendChild(element); 
      element.click();
    }
  }, [data])

  if (loading) return <div className={classes.loadingContainer}><CircularProgress /></div>;
  if (error) return <ErrorModal error={error} />;

  return (
    <div>
      <Button style={{ marginBottom: '10px' }} variant="contained" onClick={addTodo}>
        Descargar
      </Button>
    </div>

  );
};

export default DownloadReportButton;
