import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { token } from '../apollo/sessionHelper';

const ProtectedRoute = function ({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) => (token.call() ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

export default ProtectedRoute;
