/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button, CircularProgress, makeStyles, Paper, TableContainer,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import SearchBar from './searchBar';
import CreateBackupButton from './createBackupButton';
import DeleteBackupButton from './deleteBackupButton';
import ErrorModal from './errorModal';
import { BackupsQuery } from '../gql/backups';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    height: '70vh',
    width: '100%',
    '& .status-E': {
      backgroundColor: 'rgb(255, 0, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 0, 0, 0.35)!important',
      },
    },
    '& .status-I': {
      backgroundColor: 'rgb(255, 255, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(255, 255, 0, 0.65)',
      },
    },
    '& .status-B': {
      backgroundColor: 'rgb(0, 128, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(0, 128, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(0, 128, 0, 0.65)',
      },
    },
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const statusMap = {
  A: 'Activo',
  E: 'Eliminado',
  I: 'Inactivo',
  B: 'Backup',
  D: 'Docencia',
  U: 'Uso Interno',
  M: 'Implementación',
};

function selectStatus (status) {
  if (Object.prototype.hasOwnProperty.call(statusMap, status)){
    return (statusMap[status])
  } else {
    return (status)
  }
}

const areaMap = {
  CONSULTORIA: 'Consultoría',
  COMERCIAL: 'Comercial',
  PRODUCTO: 'Producto',
  DESARROLLO: 'Desarrollo',
  SOPORTE: 'Soporte',
  CENTRO_DE_CAPACITACION: 'Centro de capacitación',
};

function selectArea (area) {
  if (Object.prototype.hasOwnProperty.call(areaMap, area)){
    return (areaMap[area])
  } else {

    return (area)
  }
}



const DBList = function (props) {

  const [filter, setFilter] = useState([]);
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState();
  const [processes, setProcesses] = useState([]);
  const [filterRows, setFilterRows] = useState();
  const { loading: processLoading, data: processData, error: processError } = useQuery(BackupsQuery);
  const columns = [
    { field: 'esquema', headerName: 'Esquema', width: 150 },
    {
      field: 'razonSocialContrato',
      headerName: 'Razon social',
      width: 170,
    },
    {
      field: 'dominio',
      headerName: 'Dominio',
      width: 170,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      renderCell: (params) => selectStatus(params.value),
      width: 170,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 170,
    },
    {
      field: 'rutContrato',
      headerName: 'Rut',
      width: 120,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha creación',
      width: 200,
    },
    {
      field: 'updatedAt',
      headerName: 'Fecha modificación',
      width: 200,
    },
    {
      field: 'cantUsuarios',
      headerName: 'Cantidad de usuarios',
      width: 150,
    },
    {
      field: 'contractNumber',
      headerName: 'Número de registro',
      width: 150,
    },
    {
      field: 'contractState',
      headerName: 'Estado de contrato',
      width: 150,
    },
    {
      field: 'applicantArea',
      renderCell: (params) => selectArea(params.value),
      headerName: 'Área Solicitante',
      width: 200,
    },
    {
      field: 'solicitantUserEmail',
      headerName: 'Correo electrónico solicitante',
      width: 200,
    },
    {
      field: 'realUsersQuantity',
      headerName: 'Cantidad de usuarios real',
      width: 150,
    },
    {
      field: 'esquema2',
      renderCell: (cellValues) => (
        <Button style={{ marginBottom: '10px' }} variant="contained" href={`client/${cellValues.row.esquema}`}>
          Detalles
        </Button>
      ),
      headerName: 'Detalles',
      width: 130,
    },
    {
      field: 'bkp',
      renderCell: (cellValues) => (
        cellValues.row.estado === 'B' ? null :<CreateBackupButton domain={cellValues.row.dominio} schema={cellValues.row.esquema} isRunning={processes.includes(cellValues.row.dominio)} />
      ),
      headerName: 'Backup',
      width: 130,
    },
    {
      field: 'delete',
      renderCell: (cellValues) => (
        cellValues.row.estado === 'B' ? <DeleteBackupButton domain={cellValues.row.dominio} schema={cellValues.row.esquema} status={cellValues.row.estado} /> : null
      ),
      headerName: 'Eliminar',
      width: 150,
    },
  ];

  useEffect(() => {
    if (props.data) {
      const { allBasesBackoffice } = props.data || {};
      if (allBasesBackoffice) {
        const dataRows = allBasesBackoffice.map((base, id) => ({ ...base, id, esquema2: base.esquema }));
        setRows(dataRows);
        setFilterRows(dataRows);
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (processData) {
      const { allBackupProcess } = processData || {};
      if (allBackupProcess) {
        const dataProcesses = allBackupProcess.map((process, id) => {
          if (process.status === 'STARTED'){
            return process.base.dominio
          }
        });
        setProcesses(dataProcesses);
      }
    }
  }, [processData]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = filterRows.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field]?.toString())));
    setRows(filteredRows);
  };

  if (props.loading || processLoading) return <div className={classes.loadingContainer}><CircularProgress /></div>;
  if (props.error || processError) return <ErrorModal error={props.error}></ErrorModal>;

  return (
    <TableContainer component={Paper}>
      <div className={classes.table}>
        {rows ? (
          <DataGrid
            components={{ Toolbar: SearchBar }}
            rows={rows}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
            getRowClassName={(params) => `status-${params.getValue(params.id, 'estado')}`}
          />
        ) : null }
      </div>
    </TableContainer>

  );
};
export default DBList;
