import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation AddUserBackoffice(
    $email: String
    $firstName: String
    $isStaff: Boolean
    $isSuperuser: Boolean
    $lastName: String
    $password: String
    $schema: String!
    $username: String!
    ){
      AddUserBackoffice(
            email: $email
            firstName: $firstName
            isStaff: $isStaff
            isSuperuser :$isSuperuser
            lastName:$lastName
            password: $password
            schema: $schema
            username: $username)
      {
                  success
          }
  }`;

export const EDIT_N_OF_USERS = gql`
mutation EditNumberOfUsersBackoffice($newNumberOfUsers: Int!, $domain: String!, $sendEmail: Boolean!) {
  EditNumberOfUsersBackoffice(newNumberOfUsers: $newNumberOfUsers, domain: $domain, sendEmail: $sendEmail) {
    success
  }
}`;

export const EDIT_USER = gql`
mutation EditUserBackoffice(
  $email: String
  $firstName: String
  $isStaff: Boolean
  $isSuperuser: Boolean
  $isActive: Boolean
  $lastName: String
  $schema: String!
  $userId: Int!
  $username: String
){
  EditUserBackoffice(
    email: $email, 
    schema: $schema,
    firstName: $firstName,
    lastName: $lastName,
    isStaff: $isStaff,
    isSuperuser: $isSuperuser,
    isActive: $isActive,
    userId: $userId,
    username: $username, 
      ){
    success
  }
}`;

export const LIST = gql`
  query getAllUsersBackoffice($schema: String){
    getAllUsersBackoffice(schema: $schema){
      id
      username
      email
      firstName
      lastName
      isStaff
      isSuperuser
    }
  }
  `;
