import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField,
} from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@mui/material';
import { EDIT_N_OF_USERS } from '../gql/users';

const DATA_TEMPLATE = {
  newNumberOfUsers: null,
  // eslint-disable-next-line no-undef
  domain: process.env.REACT_APP_DOMAIN,
  sendEmail: true,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const EditNofUsers = function (props) {
  const classes = useStyles();
  const [baseData, setBaseData] = useState(DATA_TEMPLATE);
  const [addTodo, { loading, error }] = useMutation(EDIT_N_OF_USERS, { errorPolicy: 'ignore' });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBaseData({ ...baseData, newNumberOfUsers: props.initialNumber, domain: props.domain });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error) {
    return (
      <p>
        {error.message}
        {' '}
        :(
      </p>
    );
  }

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleOpen}>
        Modificar n° usuarios
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h4>Actualizar número de usuarios</h4></DialogTitle>
        </div>
        <DialogContent>
          { !loading
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <TextField id="outlined-basic" value={baseData.newNumberOfUsers} label="N° usuarios" variant="outlined" type="number" onChange={(e) => setBaseData({ ...baseData, newNumberOfUsers: e.target.value })} InputProps={{ inputProps: { min: 0 } }} />
                <FormControlLabel control={<Checkbox defaultChecked />} onChange={() => setBaseData({ ...baseData, sendEmail: !baseData.sendEmail })} label="Enviar email de notificación" />
                <Button variant="contained" onClick={() => addTodo({ variables: baseData })}> Actualizar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>

      </Dialog>
    </div>

  );
};

export default EditNofUsers;
