import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Popover, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    card: {
        textAlignLast: 'center',
    },
    button: {
      marginBottom: '10px',
    },
  }));

const InfoPopover = ({ popover }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button className={classes.button} variant="contained" onClick={handleClick}>
        Información de conexión
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Card className={classes.card}>
            {popover.content}
            <Button className={classes.button} variant="contained" onClick={() => {navigator.clipboard.writeText(popover.copyText)}}>
                Copiar
            </Button>
        </Card>
      </Popover>
    </div>
  );
};

InfoPopover.propTypes = {
  popover: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.node,
    copyText: PropTypes.string,
  }).isRequired,
};

export default InfoPopover;
