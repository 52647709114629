import React, {  } from 'react';
import {
  makeStyles, Paper, TableContainer,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import DownloadReportButton from './downloadReportButtton';


const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    height: '70vh',
    width: '100%',
    '& .status-E': {
      backgroundColor: 'rgb(255, 0, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 0, 0, 0.35)!important',
      },
    },
    '& .status-I': {
      backgroundColor: 'rgb(255, 255, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(255, 255, 0, 0.65)',
      },
    },
    '& .status-B': {
      backgroundColor: 'rgb(0, 128, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(0, 128, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(0, 128, 0, 0.65)',
      },
    },
  },
}));

const columns = [
  { field: 'name', headerName: 'Nombre', width: 250 },
  {
    field: 'description',
    headerName: 'Descripción',
    width: 720,
  },
  {
    field: 'download',
    renderCell: (cellValues) => (
      <DownloadReportButton download={cellValues.row.download}/>
    ),
    headerName: 'Descargar',
    width: 130,
  },
];

const rows = [{
  id: 0,
  name: 'Reporte esquemas último login',
  download: 'LastLoginReport',
  description: 'Incluye información sobre los esquemas y cuando un usuario realizó el último login en este.',
}, 
{
  id: 1,
  name: 'Reporte esquemas en desuso',
  download: 'UnusedSchemasReport',
  description: 'Reporta esquemas que están marcados distinto a activo o que no tiene una entrada correspondiente en bases',
},
{
  id: 2,
  name: 'Reporte uso API esquema',
  download: 'APILogsReport',
  description: 'Reporta el uso de la API de los esquemas',
},
{
  id: 3,
  name: 'Reporte usuarios tipo I',
  download: 'UsersTypeIReport',
  description: 'Reporta esquemas con usuarios tipo I',
}];

const ReportList = function () {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <div className={classes.table}>
        <DataGrid
          columns={columns}
          rows={rows}
        />
      </div>
    </TableContainer>

  );
};
export default ReportList;
