import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { token } from './sessionHelper';

const httpLink = createHttpLink({
  // eslint-disable-next-line no-undef
  uri: process.env.REACT_APP_LOCAL_API_URL,
});

const authLink = setContext((_, { headers }) =>
// get the authentication token from local storage if it exists
// return the headers to the context so httpLink can read them

  ({
    headers: {
      ...headers,
      authorization: token ? `JWT ${token.call()}` : '',
      'sec-fetch-site': 'cross-site',
    },
  }));

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
