import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField
} from '@material-ui/core';
import { CREATE_BACKUP } from '../gql/backups';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '50px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  input: {
    width: '100%',
  },
}));

const CreateBackupButton = function (props) {
  const classes = useStyles();
  const [addTodo, { data, loading, error }] = useMutation(CREATE_BACKUP, { errorPolicy: 'all' });
  const [open, setOpen] = useState(false);
  const [parsedError, setParsedError] = useState({});
  const [solicitantEmail, setSolicitantEmail] = useState('');

  useEffect(() => {
    if (error) {
      setParsedError(JSON.parse(error.message));
    }
  }, [error]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Button style={{ marginBottom: '10px' }} variant="contained" onClick={handleOpen}>
        Crear
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h2>Crear Backup</h2></DialogTitle>
        </div>
        <DialogContent>
          { !loading && !data
            ? (
              <div className={classes.containerMessage} style={{ marginBottom: '50px' }}>
                <p>
                  Estás seguro que deseas crear un backup para esquema:
                  <b>{props.schema}</b>
                  , dominio:
                  <b>{props.domain}</b>
                  <TextField className={classes.input} id="outlined-basic" label="Mail Solicitante" variant="outlined" value={solicitantEmail} onChange={e => setSolicitantEmail(e.target.value)} />
                </p>
                <Button variant="contained" onClick={() => addTodo({ variables: { baseId: props.domain, solicitantEmail: solicitantEmail  } })}> Crear Backup </Button>
              </div>
            )
            : data ? null
              : (
                <div className={classes.container} style={{ marginBottom: '50px' }}>
                  <div className={classes.loadingContainer}><CircularProgress /></div>
                </div>
              )}
          { !loading && data
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <p>
                  {error
                    ? `Código: ${parsedError.code} - Mensaje: ${parsedError.message}`
                    : `Id del proceso: ${data.BackupBaseBackoffice.processId}`}
                </p>
                <Button variant="contained" onClick={() => addTodo({ variables: { baseId: props.domain, solicitantEmail: solicitantEmail } })}> Crear Backup </Button>
              </div>
            )
            : null}
        </DialogContent>

      </Dialog>
    </div>

  );
};

export default CreateBackupButton;
