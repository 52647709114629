import { createTheme } from '@material-ui/core/styles';
import { esES } from '@material-ui/core/locale';

const defaultTheme = createTheme();
const { breakpoints, spacing } = defaultTheme;
const themeManagermas = createTheme(
  {
    palette: {
      primary: {
        main: '#1EB4FD',
      },
      secondary: {
        main: '#B0CE68',
      },
      link: {
        main: '#1A68A7',
      },
      text: {
        primary: '#5A5A5A',
        secondary: '#999999',
      },
      title: {
        main: '#5192C7',
      },
      badge: {
        main: '#E62E66',
      },
      blueTheme: {
        primary: '#1EB4FD',
        darkBlue: '#1E5591',
        blueChip: '#C5E1F2',
        whiteBlue: '#DFF0FA',
      },
      tonalOffset: 0.2,
    },
    globalVariables: {
      drawerWidth: 260,
      closeDrawerWidth: spacing(9) + 1,
    },
    typography: {
      fontFamily: '"Roboto","Helvetica Neue",Arial,sans-serif',
      h1: {
        fontWeight: 'bold',
        lineHeight: 1,
        fontSize: 36,
        color: '#5192C7',
        [breakpoints.down('xs')]: {
          fontSize: 28,
        },
      },
      h2: {
        fontWeight: 'bold',
        fontSize: 30,
        lineHeight: 1.1,
        color: '#5192C7',
        [breakpoints.down('xs')]: {
          fontSize: 24,
        },
      },
      h3: {
        fontWeight: 300,
        fontSize: 25,
        color: '#5192C7',
        [breakpoints.down('xs')]: {
          fontSize: 20, // 20
        },
        lineHeight: 1.1,
      },
      h4: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.2,
        color: '#5192C7',
        [breakpoints.down('xs')]: {
          fontSize: 16, // 16
        },
      },
      h5: {
        fontWeight: 300,
        fontSize: 16,
        lineHeight: 1.2,
        color: '#5192C7',
        [breakpoints.down('xs')]: {
          fontSize: 15,
        },
      },
      h6: {
        fontWeight: 300,
        fontSize: 14,
        color: '#5192C7',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          padding: '0 14px',
          borderRadius: 30,
          textTransform: 'none',
        },
        label: {
          height: '100%',
          color: '#1E5591',
          padding: '0.5rem',
        },
        containedPrimary: {
          color: '#FFFFFF',
          backgroundColor: '#1E5591',
        },
      },
      MuiCheckbox: {
        colorPrimary: '#1EB4FD',
      },
      MuiPickersToolbar: {
        root: {
          backgroundColor: 'yellow',
        },
      },
      MuiPickersCalendarHeader: {
        root: {
          // backgroundColor: lightBlue.A200,
          // color: "white",
        },
      },
      MuiPickersDay: {
        root: {
          color: 'green',
          '&$disabled': {
            color: 'purple',
          },
          '&$selected': {
            backgroundColor: 'blue',
          },
        },
        today: {
          color: 'red',
        },
      },
      MuiPickersModalDialog: {
        dialogAction: {
          color: 'pink',
        },
      },
    },
  },
  esES,
);

export default themeManagermas;
