import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Typography, Grid, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  header: {
    boxShadow: "none",
    borderBottom: "1px solid #e5e5e5",
  },
  sidebar: {
    height: "100%",
    maxWidth: 600,
    minWidth: 550,
  },
  appbar: {
    padding: "1rem",
  },
  closeIcon: {
    padding: 0,
    marginRight: "1rem",
  },
  children: {
    padding: "1rem",
  },
}));

const AppBarForm = ({ setState, children, title, titleRight }) => {
  const classes = useStyles();
  return (
    <AppBar style={{ minHeight: 60 }} position="sticky" color="inherit" className={classes.header}>
      <Toolbar style={{ padding: 0 }}>
        <Grid container spacing={2} alignItems="center" className={classes.appbar}>
          <Grid item container alignItems="center" xs={12}>
            <Grid item style={{ width: "2rem" }}>
              <IconButton onClick={() => setState(false)} className={classes.closeIcon}>
                <CloseIcon />
              </IconButton>
            </Grid>
            {title !== "" && (
              <Grid item xs={7}>
                <Typography>{title}</Typography>
              </Grid>
            )}
            {titleRight !== "" && (
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography style={{ color: "#5192C7" }}>{titleRight}</Typography>
              </Grid>
            )}
            {children && (
              <Grid item xs>
                {children}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
AppBarForm.propTypes = {
  setState: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  titleRight: PropTypes.string,
  children: PropTypes.node,
};
AppBarForm.defaultProps = {
  titleRight: "",
  children: <div />,
};

export default AppBarForm;