import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, Checkbox, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, IconButton, TextField,
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import { EDIT_USER } from '../gql/users';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const EditUser = function (props) {
  const classes = useStyles();
  const [userData, setUserData] = useState(props.data);
  const [addTodo, { loading, error }] = useMutation(EDIT_USER, { errorPolicy: 'ignore' });
  const [open, setOpen] = useState(false);
  const urlArray = location.pathname.split('/client/');
  const schema = urlArray[urlArray.length - 1].replace('/', '');

  useEffect(() => {
    setUserData({ ...userData, schema, userId: props.data.id });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await addTodo({ variables: userData });
    props.reloadParent();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error) {
    return (
      <p>
        {error.message}
        {' '}
        :(
      </p>
    );
  }

  return (
    <div>
      <IconButton onClick={handleOpen} aria-label="edit">
        <EditIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h2>Editar usuario</h2></DialogTitle>
        </div>
        <DialogContent>
          { !loading
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <TextField id="outlined-basic" label="Username" value={userData.username} variant="outlined" type="text" onChange={(e) => setUserData({ ...userData, username: e.target.value })} />
                <TextField id="outlined-basic" label="Email" value={userData.email} variant="outlined" type="text" onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
                <TextField id="outlined-basic" label="Nombre" value={userData.firstName} variant="outlined" type="text" onChange={(e) => setUserData({ ...userData, firstName: e.target.value })} />
                <TextField id="outlined-basic" label="Apellido" value={userData.lastName} variant="outlined" type="text" onChange={(e) => setUserData({ ...userData, lastName: e.target.value })} />
                <FormControlLabel control={<Checkbox checked={userData.isSuperuser} onChange={() => setUserData({ ...userData, isSuperuser: !userData.isSuperuser })} />} label="Superuser" />
                <Button variant="contained" onClick={() => handleSubmit()}> Actualizar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>

      </Dialog>
    </div>

  );
};

export default EditUser;
