import React, { createContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, CircularProgress, Container, Paper, TextField, Typography,
} from '@material-ui/core';
import { useMutation } from '@apollo/client';
import MainDrawer from '../../components/mainDrawer';
import MockEmail from '../../components/mockEmail';
import { SEND_EMAIL } from '../../gql/email';

const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  listContainer: {
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '10px',
    marginRight: '25px',
  },
  leftHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  leftContainer: {
    display: 'flex',
    height: '600px',
    width: '300px',
    flexDirection: 'column',
  },
}));

const buttons = [
  { text: 'Home', link: '/home' },
  { text: 'Client', link: '/client' },
];

const EmailPreview = function () {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [email, setEmail] = useState('');
  const [url, setUrl] = useState('');
  const Context = createContext({});

  const [sendEmail, { loading, data, error }] = useMutation(SEND_EMAIL);

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleSubmit = () => {
    sendEmail({
      variables: {
        email,
        username,
        clientName: name,
        url,
      },
    });
  };

  return (
    <div>
      <MainDrawer buttons={buttons} />
      <Container component={Paper} style={{ padding: '20px' }}>
        <div className={classes.header}>
          <div className={classes.leftHeaderItems}>
            <Typography align="left" variant="h2" style={{ paddingTop: '15px' }}>
              Email Preview
            </Typography>
          </div>
          <Button variant="outlined" color="secondary" onClick={handleRefresh}>
            Recargar
          </Button>
        </div>
        <Container style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'space-around', paddingTop: '50px',
        }}
        >
          <div className={classes.leftContainer}>
            <Typography align="left" variant="h3" style={{ paddingTop: '15px', marginBottom: '20px' }}>
              Datos del cliente
            </Typography>
            <TextField id="outlined-basic" label="Nombre y apellido" variant="outlined" onChange={(e) => setName(e.target.value)} />
            <TextField id="outlined-basic" label="Dominio" variant="outlined" onChange={(e) => setUrl(e.target.value)} />
            <TextField id="outlined-basic" label="Username" variant="outlined" onChange={(e) => setUsername(e.target.value)} />
            <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setEmail(e.target.value)} />
            { loading ? <CircularProgress />
              : (
                <Button color="secondary" variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            {data ? <p>Mail enviado con exito</p> : null}
            {error ? <p>{error.message}</p> : null}

          </div>
          <div style={{ height: '90vh', width: '500px' }}>
            <Context.Provider value={[name, url, username]}>
              <MockEmail name={name} Context={Context} />
            </Context.Provider>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default EmailPreview;
