import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, TextField, Dialog, DialogContent, DialogTitle, CircularProgress,
} from '@material-ui/core';
import { ADD_USER } from '../gql/users';
import ErrorModal from './errorModal';

const DATA_TEMPLATE = {
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  schema: '',
  isStaff: false,
  isSuperuser: false,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoManagermas: {
    [theme.breakpoints.down('xs')]: {
      width: 150,
    },
    width: 200,
  },
  containerLogo: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: '1rem',
    },
    marginBottom: '2rem',
  },
  loginMessage: {
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  forgotPasswordLink: {
    alignSelf: 'center',
    fontSize: 'small',
    color: theme.palette.link.main,
  },
  tooltip: {
    fontSize: 20,
  },
  header: {
    marginBottom: '10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const AddUserForm = function (props) {
  const classes = useStyles();
  const [baseData, setBaseData] = useState(DATA_TEMPLATE);
  const [addTodo, { loading, error }] = useMutation(ADD_USER, { errorPolicy: 'all' });
  const urlArray = location.pathname.split('/client/');
  const schema = urlArray[urlArray.length - 1].replace('/', '');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBaseData({ ...baseData, schema });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await addTodo({ variables: baseData });
    props.triggerRefetch();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error) return <ErrorModal error={error} />;

  return (
    <div>
      <Button variant="contained" color="secondary" onClick={() => handleOpen()}>
        Nuevo Usuario
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h2>Agregar usuario</h2></DialogTitle>
        </div>
        <DialogContent>
          { !loading
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <TextField id="outlined-basic" label="Username" variant="outlined" onChange={(e) => setBaseData({ ...baseData, username: e.target.value })} />
                <TextField id="outlined-basic" label="Email" variant="outlined" onChange={(e) => setBaseData({ ...baseData, email: e.target.value })} />
                <TextField id="outlined-basic" label="Nombre" variant="outlined" onChange={(e) => setBaseData({ ...baseData, firstName: e.target.value })} />
                <TextField id="outlined-basic" label="Apellido" variant="outlined" onChange={(e) => setBaseData({ ...baseData, lastName: e.target.value })} />
                <TextField id="outlined-basic" label="Contraseña" variant="outlined" onChange={(e) => setBaseData({ ...baseData, password: e.target.value })} />
                <Button variant="contained" onClick={() => handleSubmit()}> Agregar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>
      </Dialog>
    </div>

  );
};

export default AddUserForm;
