import { gql } from '@apollo/client';

export const DELETE_BACKUP = gql`
mutation DeleteBackupBackoffice($baseId: String!) {
  DeleteBackupBackoffice(baseId: $baseId) {
    success
  }
}`;

export const CREATE_BACKUP = gql`
mutation BackupBaseBackoffice($baseId: String!, $solicitantEmail: String!) {
  BackupBaseBackoffice(baseId: $baseId, solicitantEmail: $solicitantEmail) {
    processId
  }
}`;

export const BackupsQuery = gql`
query {
  allBackupProcess {
    id
    celeryProcessId
    status
    base {
      dominio
      esquema
    }
  }
}
`;
