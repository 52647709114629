/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress, Container, Paper, Typography,
} from '@material-ui/core';
import { useQuery, gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import MainDrawer from '../../components/mainDrawer';
import UserList from '../../components/userList';
import CompanyList from '../../components/companiesList';
import EditNofUsers from '../../components/editNumberOfUsers';
import AddUserForm from '../../components/addUserForm';
import AddCompanyForm from '../../components/addCompanyForm';
import ChangeClientStatus from '../../components/changeClientStatus';
import ErrorModal from '../../components/errorModal';

const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  listContainer: {
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '10px',
    marginRight: '25px',
  },
  leftHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  righHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const LIST = gql`
query GetBaseBackoffice($schema: String!) {
  getBaseBackoffice(schema: $schema){
    esquema
    rutContrato
    cantUsuarios
    dominio
    estado
  }
}`;

const Client = function () {
  const classes = useStyles();
  const { client } = useParams();
  const [base, setBase] = useState(null);
  const [userRefresh, setUserRefresh] = useState(false);
  const [companyRefresh, setCompanyRefresh] = useState(false);
  const { loading, error, data } = useQuery(LIST, {
    variables: {
      schema: client,
    },
  });

  const triggerUserRefetch = () => {
    setUserRefresh(!userRefresh);
  };

  const triggerCompanyRefetch = () => {
    setCompanyRefresh(!companyRefresh);
  };

  useEffect(() => {
    if (data) {
      setBase(data.getBaseBackoffice);
    }
  }, [data]);

  if (error) return <ErrorModal error={error} />;
  return (
    <div>
      <MainDrawer />
      <Container component={Paper} style={{ padding: '20px', maxWidth: '1600px' }}>
        <div className={classes.header}>
          {loading ? <div className={classes.loadingContainer}><CircularProgress /></div> : null }
          {error
            ? (
              <Typography align="left" variant="h2" style={{ paddingTop: '15px' }}>
                {error.message}
              </Typography>
            ) : null }
          {base
            ? (
              <div className={classes.leftHeaderItems}>
                <Typography align="left" variant="h2" style={{ paddingTop: '15px' }}>
                  {base.rutContrato}
                </Typography>
                <Typography align="left" variant="h2" style={{ paddingTop: '15px' }}>
                  {base.esquema}
                </Typography>
              </div>
            ) : null}
          {base
            ? (
              <div className={classes.righHeaderItems}>
                <ChangeClientStatus status={base.estado} domain={base.dominio} />
                <EditNofUsers initialNumber={base.cantUsuarios} domain={base.dominio} />
              </div>
            )
            : null}
        </div>
        {base && base.estado === 'A'
          ? (
            <div>
              <div className={classes.header}>
                <Typography align="left" variant="h3" style={{ paddingTop: '15px' }}>
                  Lista de usuarios
                </Typography>
                <AddUserForm triggerRefetch={triggerUserRefetch} />
              </div>
              <div className={classes.listContainer}>
                <UserList refresh={userRefresh} />
              </div>
              <div className={classes.header}>
                <Typography align="left" variant="h3" style={{ paddingTop: '15px' }}>
                  Lista de empresas
                </Typography>
                <AddCompanyForm triggerRefetch={triggerCompanyRefetch} />
              </div>
              <div className={classes.listContainer}>
                <CompanyList refresh={companyRefresh} />
              </div>
            </div>
          )
          : !loading ? <h1>Esquema no activo</h1> : null}
      </Container>
    </div>
  );
};

export default Client;
