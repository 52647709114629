import React, { useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import SidebarForm from '../../components/filter';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RUT } from '../../gql/filter';

const states = [
  {"id": "A", "label": "Activo", "type": "state"}, {"id": "I", "label": "Inactivo", "type": "state"},
  {"id": "B", "label": "Backup", "type": "state"}, {"id": "D", "label": "Docencia", "type": "state"}, {"id": "U", "label": "Uso interno", "type": "state"},
  {"id": "M", "label": "Implementación", "type": "state"}];

const areas = [
  {"id": "consultoria", "label": "Consultoría", "type": "area"}, {"id": "comercial", "label": "Comercial", "type": "area"},
  {"id": "producto", "label": "Producto", "type": "area"}, {"id": "desarrollo", "label": "Desarrollo", "type": "area"},
  {"id": "soporte", "label": "Soporte", "type": "area"}, {"id": "centro_de_capacitacion", "label": "Centro de capacitación", "type": "area"}];

const muiClassStyle = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      flex: 1,
    },
    "& .MuiToggleButton-root": {
      minWidth: "50px",
    },
    "& .Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "rgba(29, 179, 253, .2)",
    },
  },
}));

const BaseFilters = ({
  filters,
  sideBarOpen,
  setSideBarOpen,
  generateFilter,
  variableChange
}) => {
  const muiClass = muiClassStyle();
  const {data: filteringOptions, loading: loadingOptions} = useQuery(RUT, {variables: {stateFilter:[], rutFilter:[], applicantAreaFilter:[]}});
  const [rutFilter, setRutFilter] = React.useState([]);

  const ruts = [];

  useEffect(() => {
    
    if (filteringOptions) {
      const { allBasesBackoffice } = filteringOptions || {};
      allBasesBackoffice.forEach((rut) => {
        ruts.push({"id": rut.rutContrato, "label": rut.rutContrato, "type": "rut"});
      });
      setRutFilter(ruts);
    }
  }, [filteringOptions]);

  const onChangeSelectedState = (_event, eventName, value) => {
    if (eventName[0]) {
      variableChange(eventName, value);
    } else {
      variableChange("state", value);
    }
  };

  const onChangeSelectedRut = (_event, eventName, value) => {
    if (eventName[0]) {
      variableChange(eventName, value);
    } else {
      variableChange("rut", value);
    }
  } 

  const onChangeSelectedArea = (_event, eventName, value) => {
    if (eventName[0]) {
      variableChange(eventName, value);
    } else {
      variableChange("area", value);
    }
  }

  return (
    <SidebarForm
      state={sideBarOpen}
      setState={setSideBarOpen}
      title="Filtros"
      onApplyButton={generateFilter}
      buttonLabel="GENERAR INFORME"
    >
      {filters.general && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className={muiClass.root}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 15,
            }}
          >
            <Autocomplete
              limitTags={1}
              multiple
              size="small"
              id="tags-outlined2222"
              //eventName="selectedStates"
              options={states}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              onChange={onChangeSelectedState}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Estado de base"
                  placeholder="Estados"
                />
              )}
            />

            <Autocomplete
              limitTags={1}
              multiple
              size="small"
              id="tags-outlined2222"
              options={areas}
              getOptionLabel={(option) => option.label}
              filterSelectedOptions
              onChange={onChangeSelectedArea}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Área solicitante"
                  placeholder="Áreas"
                />
              )}
            />

            <Autocomplete
              limitTags={1}
              loading={loadingOptions}
              multiple
              size="small"
              id="tags-outlined"
              options={rutFilter}
              getOptionLabel={(option) => option.label}
              onChange={onChangeSelectedRut}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="RUT de contrato"
                  placeholder="Ruts"
                />
              )}
            />

          
          </div>
        </div>
      )}
    </SidebarForm>
  );
};

BaseFilters.propTypes = {
  filters: PropTypes.shape({
    include: PropTypes.bool,
    show: PropTypes.bool,
    general: PropTypes.bool,
    article: PropTypes.bool,
    indicators: PropTypes.bool,
  }),
  sideBarOpen: PropTypes.bool.isRequired,
  setSideBarOpen: PropTypes.func.isRequired,
};

BaseFilters.defaultProps = {
  filters: [],
};

export default BaseFilters;