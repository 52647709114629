/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@mui/material/Container/Container';
import { Button, Paper, Typography } from '@material-ui/core';
import MainDrawer from '../../components/mainDrawer';
import BackupList from '../../components/BackupList';

const useStyles = makeStyles(() => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  listContainer: {
    paddingTop: '10px',
    paddingBottom: '30px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '10px',
    marginRight: '25px',
  },
  leftHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
  },
  righHeaderItems: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Backups = function () {
  const classes = useStyles();

  return (
    <div>
      <MainDrawer />
      <Container component={Paper} style={{ padding: '20px', paddingBottom: '50px', maxWidth: '1600px' }}>
        <div className={classes.header}>
          <Typography align="left" variant="h2">
            Lista de Backups
          </Typography>
        </div>
        <BackupList />
      </Container>
    </div>
  );
};

export default Backups;
