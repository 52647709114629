import { updateVar } from './sessionHelper';

const logIn = ({ token, refreshToken }) => {
  updateVar('token', token);
  updateVar('refreshToken', refreshToken);
};

const logOut = (user) => {
  updateVar('token', undefined);
  updateVar('refreshToken', undefined);
  if (user) {
    localStorage.removeItem('apollo-cache-persist');
    localStorage.removeItem('apollo');
    user.cache.reset();
  }
};

export {
  logIn,
  logOut,
};
