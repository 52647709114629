import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  columnFlex: {
    display: 'flex',
    flexDirection: 'column',
  },
  alignStartFlex: {
    alignItems: 'flex-start',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  centerFlex: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  startFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  endFlex: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  paddingRight: {
    paddingRight: '15rem',
  },
  balanceTextStyle: {
    fontWeight: 500,
    color: 'black',
  },
  balanceFontSize: {
    fontSize: 14,
  },
  totalFontSize: {
    fontSize: 16,
  },
  classifiedCell: {
    padding: '.5rem',
  },
  borderLeft: {
    borderLeft: 'solid 1px',
  },
  screenWidth: {
    width: 'auto',
  },
  midScreenWidth: {
    width: Math.round((window.innerWidth * 0.15) / 2),
  },
  sideBarBadge: {
    color: 'white',
    backgroundColor: theme.palette.badge.main,
    opacity: '0.75',
  },
  tableContainer: {
    borderRadius: 5,
    border: '1px solid #E5E5E5',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  hideScrollBar: {
    '& ::-webkit-scrollbar': {
      display: 'none', /* Hide scrollbar for Chrome, Safari and Opera */
    },
    '-ms-overflow-style': 'none', /* IE and Edge */
    'scrollbar-width': 'none', /* Firefox */
  },
  absoluteCenter: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  centerTable: {
    display: 'flex',
    justifyContent: 'center',
    height: '35vh',
    alignItems: 'center',
  },
  noTextStyle: {
    textDecoration: 'none',
  },
  typographyAppBar: {
    fontSize: 'medium',
    color: theme.palette.blueTheme.darkBlue,
    height: 40,
    fontWeight: 'bold',
  },
  pathContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

export default useStyles;
