import { gql } from '@apollo/client';

export const GET_REPLICA_CLIENTS = gql`
query getReplicaClients {
    clients {
        schema
        clientIp
        endpoint
        taskState
        dbUser
        dbName
    }
}`;

export const DEACTIVATE_REPLICA_CLIENT = gql`
mutation DeactivateReplicaClient($schema: String!) {
    DeactivateReplicaClient(schema: $schema) {
    success
  }
}`;

export const ACTIVATE_REPLICA_CLIENT = gql`
mutation ActivateReplicaClient($schema: String!, $client_ip: String!, $client_password: String!) {
    ActivateReplicaClient(schema: $schema, clientIp: $client_ip, clientPassword: $client_password) {
    success
    errorMessage
    processId
    user
    password
    clientIp
    dbName
  }
}`;

export const GET_SCHEMAS = gql`
  query {
      allBasesBackoffice {
          esquema
          razonSocialContrato
      }
  }
  `;