import React from 'react';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import client from './apollo/apolloClient';
import Login from './views/Login';
import { themeManagermas } from './styles';
import './App.css';
import Home from './views/Home';
import Client from './views/Client';
import AddSchema from './views/AddSchema';
import EmailPreview from './views/EmailPreview';
import ProtectedRoute from './components/protectedRoute';
import Backups from './views/Backups';
import Reports from './views/Reports';
import ReplicaClients from './views/ReplicaClients';
import AddReplicaClient from './views/AddReplicaClient';

const useStyles = makeStyles(() => ({
  containerApp: {
    backgroundImage: 'linear-gradient(#DFE9EF, #FAFCFD)',
    height: '100%',
    width: '100%',
    minHeight: '100vh',
    // minWidth: 'fit-content', // ver este cambio
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px !important',
    marginLeft: '240px',
  },
}));

const App = function () {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={themeManagermas}>
      <ApolloProvider client={client}>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons+Outlined" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <div className={classes.containerApp}>
          <BrowserRouter>
            <Switch>
              <Route exact path="/login">
                <Login />
              </Route>
              <div className={classes.contentContainer}>
                <ProtectedRoute exact path="/home" component={Home} />
                <ProtectedRoute exact path="/client/:client" component={Client} />
                <ProtectedRoute exact path="/addschema" component={AddSchema} />
                <ProtectedRoute exact path="/email" component={EmailPreview} />
                <ProtectedRoute exact path="/backups" component={Backups} />
                <ProtectedRoute exact path="/reports" component={Reports} />
                <ProtectedRoute exact path="/replica_clients" component={ReplicaClients} />
                <ProtectedRoute exact path="/add_replica_client" component={AddReplicaClient} />
                <Route exact path="/">
                  <Redirect to="/home" />
                </Route>
              </div>
            </Switch>
          </BrowserRouter>
        </div>
      </ApolloProvider>
    </MuiThemeProvider>
  );
};

export default App;
