import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography } from '@material-ui/core';
import MainDrawer from '../../components/mainDrawer';
import AddReplicaClientForm from '../../components/replicas/addReplicaClientForm';

const useStyles = makeStyles((theme) => ({
  backgroundLogin: {
    backgroundImage: 'linear-gradient(#1E5591, #013771)',
    height: '100vh',
    width: '45%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px !important',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '25px',
    marginBottom: '25px',
    marginRight: '25px',
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
}));

const AddReplicaClient = function () {
  const classes = useStyles();

  return (
    <div>
      <MainDrawer />
      <div className={classes.appContainer}>
        <div className={classes.header}>
          <Typography align="left" variant="h2">
            Activar servicio de réplicas para un schema
          </Typography>
        </div>
        <Container maxWidth="xs">
          <Paper elevation={3} className={classes.paper}>
            <AddReplicaClientForm />
          </Paper>
        </Container>
      </div>
    </div>

  );
};

export default AddReplicaClient;
