import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@mui/material/MenuItem';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button, TextField, Container, Typography, CircularProgress,
} from '@material-ui/core';
import { ACTIVATE_REPLICA_CLIENT, GET_SCHEMAS } from '../../gql/replicas';
import ErrorModal from '../errorModal';

const DATA_TEMPLATE = {
  schema: '',
  client_ip: '',
  client_password: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  header: {
    marginBottom: '10px',
  },
  input: {
    width: '100%',
  },
}));

const AddReplicaClientForm = function () {
  const classes = useStyles();
  const [clientData, setClientData] = useState(DATA_TEMPLATE);
  const { loading: schemasLoading, error: schemasError, data: schemasData } = useQuery(GET_SCHEMAS);
  const [schemas, setSchemas] = useState([]);
  const [controlledError, setControlledError] = useState(false);
  const [ipError, setIpError] = useState("");

  const [addTodo, { data, loading, error }] = useMutation(ACTIVATE_REPLICA_CLIENT, { errorPolicy: 'all' });

  useEffect(() => {
    if (data) {
      if (data.ActivateReplicaClient.success == false) setControlledError(true)
    }
  }, [data]);

  useEffect(() => {
    if (schemasData) {
        const allSchemas = schemasData.allBasesBackoffice.map((base, index) => (
            { 
                esquema: base.esquema,
                razon_social: base.razonSocialContrato,
                id: index 
            }));
        setSchemas(allSchemas);
    }
  }, [schemasData]);

  const setIp = ((e) => {
    setClientData({ ...clientData, client_ip: e.target.value })
    if (!e.target.value.includes("/32")) {
      setIpError("IP debe terminar en /32")
    }
    else setIpError("")
  });

  const sortSchemas = ((a, b) => {
    if (a.esquema > b.esquema) return 1;
    if (a.name < b.name) return -1;
    return 0;
  })

  if (loading || schemasLoading) return <CircularProgress />;
  if (controlledError) {
      return data.ActivateReplicaClient.errorMessage.map((e, index) =>
      <li key={index}> {e} </li>
    );
  }
  if (data && !error) {
    return (
        <div>
            <h2>Activando la réplica</h2>
            <p>Celery Process ID: {data.ActivateReplicaClient.processId} </p>
            <p>Endpoint: Disponible cuando se cree la instancia </p>
            <p>BBDD: {data.ActivateReplicaClient.dbName} </p>
            <p>Usuario: {data.ActivateReplicaClient.user} </p>
            <p>Contraseña: {data.ActivateReplicaClient.password} </p>
            <p>IP: {data.ActivateReplicaClient.clientIp} </p>
        </div>
    );
  }

  if (error || schemasError) return <ErrorModal error={error} />;

  return (
    <Container>
      <form>
        <Typography className={classes.header} color="textSecondary" align="center">
          Activar servicio de réplica para un nuevo
          {' '}
          <b>schema</b>
        </Typography>
        <TextField 
        className={classes.input}
        select
        id="outlined-basic"
        label="Schema"
        variant="outlined"
        onChange={(e) => setClientData({ ...clientData, schema: e.target.value })} 
        >
           {schemas.sort(sortSchemas).map((schema) => (
            <MenuItem key={schema.esquema} value={schema.esquema}>
              <b>{schema.esquema}</b> ({schema.razon_social})
            </MenuItem>
          ))}
        </TextField>
        <TextField 
        className={classes.input}
        id="outlined-basic"
        label="IP cliente"
        variant="outlined"
        placeholder="190.000.00.00/32"
        onChange={(e) => setIp(e)}
        helperText={ipError}
        error={ipError != ""}/>
        <TextField className={classes.input} id="outlined-basic" label="Contraseña BBDD para usuario cliente" variant="outlined" onChange={(e) => setClientData({ ...clientData, client_password: e.target.value })} />
        <Button className={classes.input} variant="contained" onClick={() => addTodo({ variables: clientData })}> Agregar </Button>
      </form>
    </Container>
  );
};

export default AddReplicaClientForm;
