import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  containerMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '50px',
  },
}));

const ErrorModal = function (props) {
  // @ts-ignore
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [error, setError] = useState({
    shortMessage: '',
    message: '',
    code: ''
  });
  
  useEffect(() => {
    
    try{
      if (props.error.graphQLErrors){
        const errorDict = props.error.graphQLErrors[0]
        setError({
        shortMessage: 'No short message',
        message: errorDict.message,
        code: 'No code',
        })
      }
      else {
        const errorDict = JSON.parse(props.error)
        setError({
        shortMessage: errorDict.shortMessage,
        message: errorDict.message,
        code: errorDict.code,
        })
      }
    }
    catch(e){
      setError({
        shortMessage: 'could not parse short message',
        message: 'could not parse message',
        code: 'Unknown'
      });
    }    
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      <DialogTitle>{error.shortMessage}</DialogTitle>
      <DialogContent className={classes.containerMessage}>
        <p><b>Código:</b> {error.code}</p>
        <br/>
        <p>{error.message}</p>
      </DialogContent>
    </Dialog>
  );
};
export default ErrorModal;
