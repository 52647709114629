/* eslint-disable no-unused-vars */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CircularProgress, List, ListItem, ListItemText, makeStyles, Paper, TableContainer,
} from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import SearchBar from '../searchBar';
import ErrorModal from '../errorModal';
import { GET_REPLICA_CLIENTS } from '../../gql/replicas';
import DeactivateReplicaButton from './deactivateReplicaButton';
import InfoPopover from './infoPopover';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    height: '70vh',
    width: '100%',
    '& .status-E': {
      backgroundColor: 'rgb(255, 0, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 0, 0, 0.35)!important',
      },
    },
    '& .status-I': {
      backgroundColor: 'rgb(255, 255, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(255, 255, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(255, 255, 0, 0.65)',
      },
    },
    '& .status-B': {
      backgroundColor: 'rgb(0, 128, 0, 0.2)!important',
      '&:hover': {
        backgroundColor: 'rgb(0, 128, 0, 0.35)!important',
      },
      '&:selected': {
        backgroundColor: 'rgb(0, 128, 0, 0.65)',
      },
    },
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const ReplicasList = function () {
  const classes = useStyles();
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState();
  const [filterRows, setFilterRows] = useState();
  const { loading, error, data } = useQuery(GET_REPLICA_CLIENTS, { fetchPolicy: "network-only"});
  const columns = [
    { field: 'esquema', headerName: 'Esquema', width: 150 },
    { field: 'client_ip', headerName: 'IP Cliente', width: 150 },
    { field: 'endpoint', headerName: 'DB Endpoint', width: 300 },
    { field: 'task_state', headerName: 'Estado', width: 120 },
    {
      field: 'desactivar',
      renderCell: (cellValues) => (
        <DeactivateReplicaButton schema={cellValues.row.esquema} />
      ),
      headerName: 'Desactivar',
      width: 175,
    },
    {
      field: 'Información',
      renderCell: (cellValues) => (
        <InfoPopover popover={{
          title: 'Información de conexión',
          content: 
          <List>
            <ListItem>
              <ListItemText primary={`Host:`} secondary={cellValues.row.endpoint}/>
          </ListItem>
          <ListItem>
              <ListItemText primary={`Base de datos:`} secondary={cellValues.row.db_name} />
          </ListItem>
          <ListItem>
              <ListItemText primary={`Usuario:`} secondary={cellValues.row.db_user} />
          </ListItem>
          <ListItem>
              <ListItemText primary={`IP permitida:`} secondary={cellValues.row.client_ip} />
          </ListItem>
          </List>,
          copyText: `
          Host: ${cellValues.row.endpoint} \n
          Base de datos: ${cellValues.row.db_name} \n
          Usuario: ${cellValues.row.db_user} \n
          IP permitida: ${cellValues.row.client_ip}`
        }}/>
      ),
      headerName: 'Información',
      width: 200,
    },
  ];

  useEffect(() => {
    if (data) {
      const allReplicaClients = data || [{}];
        const dataRows = allReplicaClients.clients.map((client, index) => (
            { 
                esquema: client.schema,
                client_ip: client.clientIp,
                endpoint: client.endpoint,
                task_state: client.taskState,
                db_name: client.dbName,
                db_user: client.dbUser,
                id: index 
            }));
        setRows(dataRows);
        setFilterRows(dataRows);
    }
  }, [data]);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = filterRows.filter((row) => Object.keys(row).some((field) => searchRegex.test(row[field].toString())));
    setRows(filteredRows);
  };

  if (loading) return <div className={classes.loadingContainer}><CircularProgress /></div>;
  if (error) return <ErrorModal error={error}></ErrorModal>;

  return (
    <TableContainer component={Paper}>
      <div className={classes.table}>
        {rows ? (
          <DataGrid
            components={{ Toolbar: SearchBar }}
            rows={rows}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            componentsProps={{
              toolbar: {
                value: searchText,
                onChange: (event) => requestSearch(event.target.value),
                clearSearch: () => requestSearch(''),
              },
            }}
          />
        ) : null }
      </div>
    </TableContainer>

  );
};
export default ReplicasList;
