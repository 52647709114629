import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, CircularProgress, Dialog, DialogContent, DialogTitle, FormControl,
} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { UPDATE_STATUS } from '../gql/client';

const DATA_TEMPLATE = {
  newStatus: '',
  domain: '',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ChangeClientStatus = function (props) {
  const classes = useStyles();
  const [baseData, setBaseData] = useState(DATA_TEMPLATE);
  const [status, setStatus] = useState('');
  const [addTodo, { data, loading, error }] = useMutation(UPDATE_STATUS, { errorPolicy: 'ignore' });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setBaseData({ ...baseData, domain: props.domain });
    setStatus(props.status);
  }, []);

  useEffect(() => {
    if (data) {
      data.UpdateBaseStatus.success ? window.location.reload() : null;
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error) {
    return (
      <p>
        {error.message}
        {' '}
        :(
      </p>
    );
  }

  return (
    <div>
      <Button variant="outlined" color="secondary" onClick={handleOpen}>
        Cambiar estado
      </Button>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h4>Actualizar estado de la base</h4></DialogTitle>
        </div>
        <DialogContent>
          { !loading
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <FormControl>
                  <Select
                    variant="outlined"
                    defaultValue={status}
                    onChange={(e) => setBaseData({ ...baseData, newStatus: e.target.value })}
                  >
                    <MenuItem value="A">Activo</MenuItem>
                    <MenuItem value="I">Inhabilitado</MenuItem>
                    <MenuItem value="E">Eliminado</MenuItem>
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={() => addTodo({ variables: baseData })}> Actualizar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>

      </Dialog>
    </div>

  );
};

export default ChangeClientStatus;
