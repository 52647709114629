import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import {
  Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, TextField,
} from '@material-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import { EDIT_COMPANY } from '../gql/company';

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('xs')]: {
      padding: '2rem',
    },
    padding: '3rem',
    borderRadius: 15,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingInline: '100px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const EditCompany = function (props) {
  const classes = useStyles();
  const [companyData, setCompanyData] = useState(props.data);
  const [addTodo, { loading, error }] = useMutation(EDIT_COMPANY, { errorPolicy: 'ignore' });
  const [open, setOpen] = useState(false);
  const urlArray = location.pathname.split('/client/');
  const schema = urlArray[urlArray.length - 1].replace('/', '');

  useEffect(() => {
    setCompanyData({ ...companyData, schema, companyId: props.data.empresa });
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    await addTodo({ variables: companyData });
    props.reloadParent();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (error) {
    return (
      <p>
        {error.message}
        {' '}
        :(
      </p>
    );
  }

  return (
    <div>
      <IconButton onClick={handleOpen} aria-label="edit">
        <EditIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
        <div className={classes.container}>
          <DialogTitle><h2>Editar empresa</h2></DialogTitle>
        </div>
        <DialogContent>
          { !loading
            ? (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <TextField id="outlined-basic" label="Nombre de fantasía" value={companyData.nomFantasia} variant="outlined" type="text" onChange={(e) => setCompanyData({ ...companyData, nomFantasia: e.target.value })} />
                <TextField id="outlined-basic" label="Rut" value={companyData.rut} variant="outlined" type="text" onChange={(e) => setCompanyData({ ...companyData, rut: e.target.value })} />
                <TextField id="outlined-basic" label="Giro" value={companyData.giro} variant="outlined" type="text" onChange={(e) => setCompanyData({ ...companyData, giro: e.target.value })} />
                <TextField id="outlined-basic" label="Email" value={companyData.emailContac} variant="outlined" type="text" onChange={(e) => setCompanyData({ ...companyData, emailContac: e.target.value })} />
                <Button variant="contained" onClick={() => handleSubmit()}> Actualizar </Button>
              </div>
            )
            : (
              <div className={classes.container} style={{ marginBottom: '50px' }}>
                <div className={classes.loadingContainer}><CircularProgress /></div>
              </div>
            )}
        </DialogContent>

      </Dialog>
    </div>

  );
};

export default EditCompany;
